import React from "react";
import ReactDOM from "react-dom";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import App from "./App";

LogRocket.init("bghhsb/course-viewerskillsuitecom");
setupLogRocketReact(LogRocket);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
document.getElementById("root"));
