import CourseViewer, { client, config } from "@skillsuite/course-viewer";
import LogRocket from "logrocket";
import logo from "./logo.png";
import "@skillsuite/course-viewer/dist/viewer/assets/course-viewer.css";

const StorageKey = "@skillsuite/course-viewer/token-new";

const { pathname, protocol, hostname, href, search } = window.location;

if (hostname.search("local") > -1) {
  config.baseURL = `${protocol}//${hostname}:${process.env.REACT_APP_API_PORT}`;
} else {
  config.log = function() {};
  config.baseURL = `${protocol}//${hostname}`;
}

const App = () => {
  const slug = pathname.slice(1);
  const params = new URLSearchParams( search.slice(1) );
  const code = params.get("code");

  // Token
  const tokenFromURL = params.get("token");
  const previewFromURL = params.get("preview");
  const isPreview = previewFromURL !== null;
  const previewPrefix = isPreview ? "/preview" : "";
  const token = tokenFromURL || localStorage.getItem(StorageKey);

  config.getToken = () => token;

  const onListFiles = id => client.get(`/v2/lesson/upload/${id}/upload_items`);

  const onFileDelete = id => client.delete(`/v2/lesson/upload/upload_item/${id}`);

  const onFileAppendLink = async (id, link) => {
    const deleteLink = () => client.delete(`/v2/lesson/upload/upload_item/${link.id}`);

    const createLink = value => {
      if (link.external_link.length > 0) {
        return client.post(`/v2/lesson/upload/${id}/upload_item`, {
          data: {
            external_links: [link.external_link]
          }
        });
      }

      return [undefined];
    }

    const updateLink = async () => {
      const res = await Promise.all([
        deleteLink(),
        createLink(),
      ]);

      return res[1][0];
    }

    if (link.id)
      return await updateLink();

    return await createLink();
  }

  const onFileUpload = async (id, org_node, files) => {
    function uploadFile(file) {
      const data = new FormData();
      data.append("label", file.name);
      data.append("org_node", org_node);
      data.append("file_name", file.name);
      data.append("type", file.type.split("/")[0]);
      data.append("file", file);

      return client.post(`/v2/lesson/media-resource/`, { data, headers: false });
    }

    const response = await Promise.all( files.map(uploadFile) );
    const resources = response.filter(item => !!item?.id).map(item => item.id);

    if (resources.length === 0)
      return null;

    return await client.post(`/v2/lesson/upload/${id}/upload_item`, {
      data: {
        resources: response.map(item => item.id)
      }
    })
  }

  const onOverview = async () => {
    const isValidCode = code?.length > 0;
    const isValidToken = typeof config.getToken() === "string" && config.getToken()?.length > 0;

    if (isValidCode === false && (typeof tokenFromURL !== "string" || isValidToken === false))
      return false;

    try {
      if (isValidToken === false) {
        const res = await client.post(`/api/v3/user/partner/token/`, { data: { code } });

        if (res === false)
          return resetAll();

        localStorage.setItem(StorageKey, res.access_token);
        config.getToken = () => res.access_token;

        await new Promise(resolve => setTimeout(resolve, 800));
      }

      let overview = await client.get(`/api/v3/course_viewer${previewPrefix}?slug=${slug}`);

      if (overview === 401)
        overview = { status: 401 };

      await fetchUserInfo(overview);

      return {
        is_preview: isPreview,
        status: true,
        ...overview,
      };
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  const onResources = async ({ id }) => {
    const res = await client.get(`/api/v3/course_viewer/resources/supplemental/${id}${previewPrefix}`);

    return res?.results || false;
  }

  const onAudioClick = async ({ text, lang }) => await client.get(`/convert?text=${text}&lang=${lang}`);
  const onSection = async ({ section }) => await client.get(`/api/v3/course_viewer/section${previewPrefix}/${section}`);
  const onQuiz = async ({ quiz }) => await client.get(`/api/v3/course_viewer/form/${isPreview ? "preview" : "registration"}/${quiz}`);
  const onProgress = async ({ section, component, question, responses }) => {
    const params = {};
    const URI = (function() {
      if (question)
        return `/api/v3/registration/form/question/${question}`;

      if (component)
        return `/api/v3/registration/component/${component}/completed`;

      return `/api/v3/registration/section/${section}/completed`;
    })();

    if (responses)
      params.data = { registration_responses: responses };

    return await client.patch(URI, params);
  }

  const onError = ({ error }) => LogRocket.captureException(error);

  const fetchUserInfo = async course => {
    if (process.env.NODE_ENV === "development")
      return null;

    try {
      const user = await client.get(`/v2/user/data`);

      LogRocket.identify(user.id, {
        name: user.name,
        email: user.email,
        course: {
          id: course.id,
          name: course.label,
        }
      });
    } catch (e) {
      // console.log(e);
    }
  }

  const resetAll = () => {
    localStorage.removeItem(StorageKey);

    if (href.includes("refresh"))
      return false;

    const url = new URL(href);
    url.searchParams.set("refresh", "true");

    return window.location.assign(url.search);
  }

  return (
    <CourseViewer
      onFileAppendLink={ onFileAppendLink }
      onFileDelete={ onFileDelete }
      onFileUpload={ onFileUpload }
      onListFiles={ onListFiles }

      onAudioClick={ onAudioClick }
      onResources={ onResources }
      onOverview={ onOverview }
      onProgress={ onProgress }
      onSection={ onSection }
      onError={ onError }
      onQuiz={ onQuiz }
      loader={ logo }
    />
  );
}

export default App;
